
import React from 'react';
import {motion} from 'framer-motion';
import './styles.css';

const AnimatedPageWrapper = ({children, id}) => {
    return <motion.div id={id} key={id} className="web-page" {...animations}>
        {children}
    </motion.div>
}

export default AnimatedPageWrapper;

const animations = {
    initial: {opacity: 0, x: -100},
    animate: {opacity: 1, x:0},
    exit: {opacity: 0, x: 100},
    transition: { type: " Inertia", duration: 0.5 }
}