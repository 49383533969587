import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby";
import {log} from './../../utilities/measure.js';
import JsonLd from './json.jsx';


            {/* {JSON.parse({
        "@context": "https://schema.org/",
        "@type": "LearningResource",
        "learningResourceType": "Card game math problems",
        "educationalUse": "game based learning practice",
        "educationalLevel": "intermediate",
        "teaches": "unit conversion, multiplication and fractions in the context of medication dosage",
        "description": "use unit conversion, multiplication and fractions to solve medical math print and play problems",
        "about": "A print and play card game about prescribing patients the correct dosage of medication",
        "accessMode": "textual",
        "assesses": "math, multiplication, fractions, unit conversion",
        "name": "Medication Math",
        "author": {
          "@type": "Person",
          "givenName": "Victoria",
          "email": "vcb@stimsims.com"
        },
        "educationalAlignment": {
          "@type": "AlignmentObject",
          "alignmentType": "educationalSubject: math, readingLevel: intermediate, mathLevel: intermediate",
          "educationalFramework": "game based learning",
          "targetDescription": "applying basic math operations to solve a quantity problem",
          "targetName": "basic math operations in a game context",
          "targetUrl": "https://link.springer.com/referenceworkentry/10.1007%2F978-1-4419-1428-6_437"
        }
      })} */}


function SEO({ description, lang, meta, metaImage, title, pathname }) {
  const query = useStaticQuery(
    graphql`
      query SeoQuery {
        site {
          ...SiteInformation
        }
        logo: file(relativePath: { eq: "twitter.png" }) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              # Makes it trivial to update as your page's design changes.
              fixed(width: 1200, height: 675) {
                ...GatsbyImageSharpFixed
              }
            }
          }
      }
    `
  )
  const site = query.site;
  log(`SEO title ${title} meta ${meta}`);
  log(`SEO query`, query);
  const metaDescription = description || site.siteMetadata.description
  let image;
  if(metaImage && metaImage.src){
    image = metaImage;
  }else{
    image = {
        src: site.siteMetadata.siteUrl + query.logo.childImageSharp.fixed.src,
        width: 1200,
        height: 675
    }
  }
  log(`SEO image`, image);
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null
  return (
    <>

    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: site.siteMetadata.keywords.join(","),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
            [
                {
                  property: "og:image",
                  content: image.src,
                },
                {
                  property: "og:image:width",
                  content: image.width,
                },
                {
                  property: "og:image:height",
                  content: image.height,
                },
                {
                  name: "twitter:card",
                  content: image.width >= 600?"summary_large_image":"summary",
                },
            ]
        )
        .concat(meta)}
    />
    <JsonLd data={{
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "Medication Math",
        "url": "https://medicationmath.stimsims.com",
        "educationalUse": "game based learning practice",
        "educationalLevel": "intermediate",
        "teaches": "unit conversion, multiplication and fractions in the context of medication dosage",
        "author": {
          "@type": "Person",
          "givenName": "Victoria",
          "email": "vcb@stimsims.com"
        },
        "image": {
          "@type": "ImageObject",
          "caption": "Playing cards with medication icons on them",
          "contentUrl": "https://medicationmath.stimsims.com/static/5fd16bc87e2ecbe4f56245d4cbde97a9/ba507/twitter.png",
          "encodingFormat": "image/png"
        }
    }} />
    </>
  )
}
SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}
SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
}
export default SEO